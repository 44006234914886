const buttonFaces = [

    "( ・◡・ )",
    "( ・o・ )",
    "( ・-・ )",
    "( ・-・ )",
    "",
    "",
    "",
    "",
    "",
    "",
    ""

]

// "(* ^ ω ^)", "(´ ∀ ` *)", "٩(◕‿◕｡)۶", "(o^▽^o)", "(⌒▽⌒)☆", "(✯◡✯)", "(☆▽☆)", "(´｡• ᵕ •｡`)", "(´• ω •`)", 
//     "(╯✧▽✧)╯", "(´･ᴗ･ ` )", "\\_( ◉ 3 ◉ )_/¯", "Σ(‘◉⌓◉’)", "ヽ(°〇°)ﾉ", "( : ౦ ‸ ౦ : )", "(°ロ°) !", "( ´ ▽ ` )ﾉ", 
//     "ಠ⌣ಠ", "(◕︿◕✿)", "ヽ( `д´*)ノ", "(・`ω´・)"


export default buttonFaces;